















import Vue from 'vue'
export default Vue.extend({
  name: 'TextArea',
  props: {
    label: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: String,
      default: '3000'
    },
    rows: {
      type: Number,
      default: 1
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleInput (value: string) {
      this.$emit('input', value)
    }
  }
})
