


































































import Vue from 'vue'
import firebaseApp, { analytics } from '@/plugins/firebase'
import { isPWA } from '@/utils/isPWA'

export default Vue.extend({
  name: 'App',

  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Create An Email',
    changed (metaInfo) {
      analytics.setCurrentScreen(metaInfo.title ?? '')
      analytics.logEvent('page_view', {
        page_title: metaInfo.title
      })
      analytics.logEvent('screen_view', {
        app_name: isPWA() ? 'pwa' : 'web',
        screen_name: metaInfo.title
      })
    }
  },

  data: () => ({
    //
  }),

  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    usersName () {
      if (this.isLoggedIn) {
        return this.$store.getters.usersName
      }
      return ''
    }
  },

  mounted () {
    firebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        analytics.logEvent('login', { location: 'default' })
        this.$store.dispatch('handleLoggedIn', user)
      } else {
        this.$store.dispatch('handleLoggedOut')
      }
    })
  },

  methods: {
    async logout () {
      firebaseApp.auth().signOut()
    }
  }
})
