import TextArea from '@/components/TextArea.vue'
import TextInput from '@/components/TextInput.vue'
import router from '@/router'
import store from '@/store'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import VuePersist from 'vue-persist'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

Vue.use(VueMeta)
Vue.use(VuePersist)

Vue.component('TextInput', TextInput)
Vue.component('TextArea', TextArea)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
