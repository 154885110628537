import { analytics } from '@/plugins/firebase'
import firebase from 'firebase'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

interface User {
  uid: string;
  displayName: string;
  photo: string;
}

export default new Vuex.Store({
  state: {
    user: {} as User
  },
  getters: {
    isLoggedIn (state) {
      return !!state.user.uid
    },
    usersName (state) {
      return state.user.displayName
    }
  },
  mutations: {
    setUser (state, user: User) {
      state.user = user
    }
  },
  actions: {
    handleLoggedIn (context, firebaseUser: firebase.User) {
      const user = {
        uid: firebaseUser.uid,
        displayName: firebaseUser.displayName,
        photo: firebaseUser.photoURL
      }
      context.commit('setUser', user)

      analytics.setUserId(firebaseUser.uid)
    },
    handleLoggedOut (context) {
      context.commit('setUser', {})
    }
  },
  plugins: [vuexLocal.plugin]
})
