import store from '@/store'
import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    alias: '/create',
    component: () => import(/* webpackChunkName: "create" */ '@/views/Create.vue')
  },
  {
    path: '/t/:templateId/:slug',
    name: 'Template',
    component: () => import(/* webpackChunkName: "template" */ '@/views/Template.vue')
  },
  {
    path: '/templates',
    name: 'Templates',
    component: () => import(/* webpackChunkName: "templates" */ '@/views/Recent.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next({ name: 'Home' })
      } else {
        next()
      }
    }
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue')
  }
]
const router = new Router({
  routes,
  mode: 'history'
})

export default router
