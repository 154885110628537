import config from '@/utils/config'
import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseApp = firebase.initializeApp({
  apiKey: config.firebaseApiKey,
  databaseURL: config.firebaseDatabaseURL,
  projectId: config.firebaseProjectId,
  authDomain: config.firebaseAuthDomain,
  appId: config.firebaseAppId,
  measurementId: config.firebaseMeasurementId
})

export const firestore = firebaseApp.firestore()
export const analytics = firebaseApp.analytics()

if (process.env.NODE_ENV !== 'production') {
  // firebaseApp.functions().useFunctionsEmulator('http://localhost:5001')
  analytics.setAnalyticsCollectionEnabled(false)
}

export const createTemplate = firebaseApp.functions().httpsCallable('createTemplate')
export const templatedViewed = firebaseApp.functions().httpsCallable('templateViewed')

export default firebaseApp
