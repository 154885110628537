






import Vue from 'vue'
export default Vue.extend({

  name: 'TextInput',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      inputValue: ''
    }
  },

  methods: {
    handleInput (value: string) {
      this.inputValue = value
      this.$emit('input', value)
    }
  }
})
